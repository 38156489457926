import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Avatar,
  Typography,
  Box,
  Grid,
  Divider,
  IconButton,
  Button
} from '@mui/material';
import api from '../../../services/api';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface Match {
  secureId: string;
  mapName: string;
  mapImage: string;
  agentName: string;
  agentImage: string;
  trScore: number;
  acs: string;
  placement: number;
  kdRatio: number;
  kills: number;
  deaths: number;
  assists: number;
  hsPercentage: string;
  match_date: string;
  roundsWon: number;
  roundsLost: number;
}

interface MatchesDialogProps {
  open: boolean;
  onClose: () => void;
  userId: string;
}

const MatchesDialog: React.FC<MatchesDialogProps> = ({ open, onClose, userId }) => {
  const [matches, setMatches] = useState<Match[]>([]);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await api.get(`/last-five-matches/${encodeURIComponent(userId)}`);
        setMatches(response.data);
      } catch (error) {
        console.error('Erro ao buscar as partidas:', error);
      }
    };

    if (open && userId) {
      fetchMatches();
    }
  }, [open, userId]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" className='dialog-matches'>
      <DialogTitle>
        Last five matches
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FontAwesomeIcon icon={faXmark}/>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {matches.map((match) => (
            <React.Fragment key={match.secureId}>
              <ListItem
                alignItems="flex-start"
                style={{
                  border: match.roundsWon > match.roundsLost ? '1px solid #31435593' : '1px solid #31435593',
                  borderLeft: match.roundsWon > match.roundsLost ? '4px solid #5ee790' : '4px solid #e4485d',
                  borderRadius: '5px',
                  marginBottom: '8px'
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={1} sx={{display: 'grid', placeItems: 'center'}}>
                    <Avatar variant="rounded" src={match.mapImage} alt={match.mapName} sx={{ width: 56, height: 56 }} />
                    <Typography variant="caption" display="block" align="left">{match.mapName}</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Box display="flex" alignItems="center">
                      <Avatar src={match.agentImage} alt={match.agentName} sx={{ width: 40, height: 40, marginRight: 2 }} />
                      <Box>
                        <Typography variant="body1">{match.agentName}</Typography>
                        <Typography variant="body2" color="textSecondary">{new Date(match.match_date).toLocaleDateString()}</Typography>
                      </Box>
                    </Box>
                    <Box mt={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          <Typography variant="body2">K/D/A: <strong>{`${match.kills}/${match.deaths}/${match.assists}`}</strong></Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body2">HS%: <strong>{match.hsPercentage}%</strong></Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body2">ACS: <strong>{match.acs}</strong></Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body2">Score: <strong>{match.trScore}</strong></Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <Button onClick={onClose} color="primary">Fechar</Button>
    </Dialog>
  );
};

export default MatchesDialog;
