import React from 'react';
import { AppBar, Toolbar, IconButton, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';

const Navbar: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await api.post('/logout');
      localStorage.removeItem('token');
      navigate('/');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const handleLogoClick = () => {
    navigate('/home');
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#0F1923' }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1,}}>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/assets/images/logo/imortal.png`}
            alt="Logo"
            sx={{ height: '50px', cursor: 'pointer' }}
            onClick={handleLogoClick}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogout}
          startIcon={<FontAwesomeIcon icon={faRightFromBracket} />}
          sx={{
            borderRadius: '4px', // Remove o formato redondo
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;