import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './index.css';
import Login from './pages/Login';
import Home from './pages/Home';
import Maps from './pages/Maps';
import Agents from './pages/Agents';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF4655', // Cor vermelha do Valorant
    },
    secondary: {
      main: '#FFFFFF', // Cor secundária, branco
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif', // Font padrão do Material-UI
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home/matches/:userId" element={<Home />} />
          <Route path="/maps/:userId" element={<Maps />} />
          <Route path="/agents/:userId/:mapId" element={<Agents />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
