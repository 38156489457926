import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, Avatar, Button, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../services/api';
import Main from '../../components/Main';
import MatchesDialog from '../../components/Specific/MatchesDialog';

// Função para obter o caminho da imagem de acordo com o trScore
const getTierImage = (trScore: number) => {
  if (trScore >= 825) {
    return process.env.PUBLIC_URL + '/assets/images/tiers/s.svg';
  } else if (trScore >= 650) {
    return process.env.PUBLIC_URL + '/assets/images/tiers/a.svg';
  } else if (trScore >= 475) {
    return process.env.PUBLIC_URL + '/assets/images/tiers/b.svg';
  } else if (trScore >= 300) {
    return process.env.PUBLIC_URL + '/assets/images/tiers/c.svg';
  } else {
    return process.env.PUBLIC_URL + '/assets/images/tiers/d.svg';
  }
};

interface User {
  secure_id: string;
  username: string;
  rank: {
    name: string;
    image: string;
  };
  stats: {
    acs: number;
    kdRatio: number;
    kast: number;
    hsPercentage: number;
    damagePerRound: number;
    kills: number;
    deaths: number;
    assists: number;
    trScore: number;
  };
}

const Home: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [matchesDialogOpen, setMatchesDialogOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/users');
        const usersData = response.data;

        const usersWithStats = await Promise.all(
          usersData.map(async (user: User) => {
            const statsResponse = await api.get(`/player/${user.secure_id}/stats`);
            return {
              ...user,
              rank: statsResponse.data.rank,
              stats: statsResponse.data.stats,
            };
          })
        );

        setUsers(usersWithStats);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar os usuários e suas estatísticas:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const match = location.pathname.match(/\/home\/matches\/([\w-]+)/);
    if (match && match[1]) {
      const userId = match[1];
      setMatchesDialogOpen(true);
      setSelectedUserId(userId);
    }
  }, [location.pathname]);

  const handleNavigateToMaps = (userId: string) => {
    navigate(`/maps/${userId}`);
  };

  const openMatchesDialog = (userId: string) => {
    navigate(`/home/matches/${userId}`);
  };

  const handleCloseMatchesDialog = () => {
    setMatchesDialogOpen(false);
    navigate('/home');
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <Main>
      <Grid container spacing={4} style={{ padding: '20px' }}>
        {users.map((user) => (
          <Grid item xs={12} sm={6} md={4} key={user.secure_id}>
            <Card
              style={{
                backgroundColor: '#1b2733',
                color: 'white',
                borderRadius: '3px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              }}
            >
              <CardContent>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <Box display="flex" alignItems="center">
                    <Avatar src={user.rank.image} alt={user.rank.name} style={{ marginRight: '10px', width: '50px', height: '50px' }} />
                    <Typography variant="h5" component="div">
                      {user.username}
                    </Typography>
                  </Box>
                  <img src={getTierImage(user.stats.trScore)} alt="Tier" style={{ width: '50px', height: '50px' }} />
                </div>
                <Typography variant="h6" component="div" style={{ marginBottom: '10px' }}>
                  Rank: <strong>{user.rank.name}</strong>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      ACS: <strong>{user.stats.acs}</strong>
                    </Typography>
                    <Typography variant="body1" component="div">
                      K/D Ratio: <strong>{user.stats.kdRatio}</strong>
                    </Typography>
                    <Typography variant="body1" component="div">
                      K/D/A: <strong>{user.stats.kills}/{user.stats.deaths}/{user.stats.assists}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      KAST: <strong>{user.stats.kast}%</strong>
                    </Typography>
                    <Typography variant="body1" component="div">
                      HS%: <strong>{user.stats.hsPercentage}%</strong>
                    </Typography>
                    <Typography variant="body1" component="div">
                      Score: <strong>{user.stats.trScore}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" component="div">
                      Damage per Round: <strong>{user.stats.damagePerRound}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleNavigateToMaps(user.secure_id)}
                      style={{ backgroundColor: '#ffffff26' }}
                    >
                      Agents
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => openMatchesDialog(user.secure_id)}
                      style={{ backgroundColor: '#ffffff26' }}
                    >
                      Matches
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="textSecondary" component="div">
                      * Last 5 matches
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <MatchesDialog open={matchesDialogOpen} onClose={handleCloseMatchesDialog} userId={selectedUserId || ''} />
      </Main>
  );
};

export default Home;
