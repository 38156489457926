import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../services/api';
import Main from '../../components/Main';

interface Agent {
  id: number;
  name: string;
  path: string;
  is_primary?: boolean; // Adicionando a propriedade is_primary ao Agent
}

interface MapData {
  id: number;
  name: string;
  path: string;
  agents: Agent[];
}

const Maps: React.FC = () => {
  const { userId } = useParams<{ userId: string }>(); // Obtém o userId dos parâmetros da URL
  const [mapsData, setMapsData] = useState<MapData[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllMaps = async () => {
      try {
        // Buscar todos os mapas
        const mapsResponse = await api.get('/maps');
        const allMaps = mapsResponse.data;

        // Buscar os mapas e agentes do usuário
        const userMapsResponse = await api.get(`/user/maps/${userId}`);
        const userMaps = userMapsResponse.data.reduce((acc: MapData[], current: any) => {
          const mapIndex = acc.findIndex((map) => map.id === current.map.id);
          if (mapIndex > -1) {
            acc[mapIndex].agents.push({
              ...current.agent,
              is_primary: current.is_primary === 1, // Set is_primary com base no valor do campo
            });
          } else {
            acc.push({
              id: current.map.id,
              name: current.map.name,
              path: current.map.path,
              agents: [
                {
                  ...current.agent,
                  is_primary: current.is_primary === 1, // Set is_primary com base no valor do campo
                },
              ],
            });
          }
          return acc;
        }, []);

        // Combina os dados de todos os mapas com os agentes do usuário
        const combinedMaps = allMaps.map((map: MapData) => {
          const userMap = userMaps.find((userMap: { id: number; }) => userMap.id === map.id);
          return userMap ? userMap : { ...map, agents: [] };
        });

        setMapsData(combinedMaps);
      } catch (error) {
        console.error('Erro ao buscar os mapas:', error);
      }
    };

    fetchAllMaps();
  }, [userId]);

  const handleSelectMap = (mapId: number) => {
    navigate(`/agents/${userId}/${mapId}`);
  };

  return (
    <Main>
      <Container maxWidth="md" style={{ padding: '20px' }}>
        <Grid container spacing={3}>
          {mapsData.map((map) => (
            <Grid item xs={12} sm={6} key={map.id}>
              <Card
                onClick={() => handleSelectMap(map.id)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#1b2733',
                  color: 'white',
                  borderRadius: '3px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardActionArea>
                  <img
                    src={process.env.PUBLIC_URL + '/assets/images/maps/' + map.path}
                    alt={map.name}
                    style={{ width: '100%', height: '220px', objectFit: 'cover' }}
                  />
                  <CardContent>
                    <Typography variant="h6">{map.name}</Typography>
                    {map.agents.length > 0 && (
                      <Typography variant="body2" color="#eaecee">
                        Agentes:{' '}
                        {map.agents.map((agent, index) => (
                          <React.Fragment key={agent.id}>
                            {index > 0 && ', '}
                            <span style={{ fontWeight: agent.is_primary ? 'bold' : 'normal' }}>
                              {agent.name}
                            </span>
                          </React.Fragment>
                        ))}
                      </Typography>
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Main>
  );
};

export default Maps;
