import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardActionArea, CardContent, Typography, Button, Box, Radio } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../services/api';
import Main from '../../components/Main';

interface Agent {
  id: number;
  name: string;
  path: string;
  position_id: number;
}

interface Position {
  id: number;
  name: string;
  path: string;
}

interface UserMapAgent {
  agent: Agent;
  is_primary: boolean;
}

interface MapData {
  id: number;
  name: string;
  path: string;
}

const Agents: React.FC = () => {
  const { userId, mapId } = useParams<{ userId: string; mapId: string }>();
  const [userName, setUserName] = useState<string>('');
  const [selectedAgents, setSelectedAgents] = useState<number[]>([]);
  const [primaryAgent, setPrimaryAgent] = useState<number | null>(null);
  const [agentsData, setAgentsData] = useState<Agent[]>([]);
  const [filteredAgents, setFilteredAgents] = useState<Agent[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);
  const [selectedPosition, setSelectedPosition] = useState<number | null>(null);
  const [mapData, setMapData] = useState<MapData | null>(null);
  const navigate = useNavigate();

  useEffect(() => {

    const fetchMapData = async () => {
      try {
        const response = await api.get(`/maps/${mapId}`);
        setMapData(response.data[0]);
      } catch (error) {
        console.error('Erro ao buscar o mapa:', error);
      }
    };

    const fetchMapAgents = async () => {
      try {
        const response = await api.get(`/map/agents/${userId}/${mapId}`);
        const userMapAgents: UserMapAgent[] = response.data;

        const agentIds = userMapAgents.map((uma) => uma.agent.id);
        const primaryAgentId = userMapAgents.find((uma) => uma.is_primary)?.agent.id || null;

        setUserName(response.data[0].user.username);

        setSelectedAgents(agentIds);
        setPrimaryAgent(primaryAgentId);

        const allAgentsResponse = await api.get('/agents');
        setAgentsData(allAgentsResponse.data);
        setFilteredAgents(allAgentsResponse.data);
      } catch (error) {
        console.error('Erro ao buscar os agentes:', error);
      }
    };

    const fetchPositions = async () => {
      try {
        const response = await api.get('/positions');
        setPositions(response.data);
      } catch (error) {
        console.error('Erro ao buscar as posições:', error);
      }
    };


    fetchMapData();
    fetchMapAgents();
    fetchPositions();
  }, [mapId, userId]);

  const handleSelectAgent = (agent: Agent) => {
    if (selectedAgents.includes(agent.id)) {
      const updatedSelectedAgents = selectedAgents.filter((a) => a !== agent.id);
      setSelectedAgents(updatedSelectedAgents);

      if (primaryAgent === agent.id) {
        setPrimaryAgent(updatedSelectedAgents.length > 0 ? updatedSelectedAgents[0] : null);
      }
    } else {
      const updatedSelectedAgents = [...selectedAgents, agent.id];
      setSelectedAgents(updatedSelectedAgents);

      if (primaryAgent === null) {
        setPrimaryAgent(agent.id);
      }
    }
  };

  const handleSetPrimaryAgent = (agentId: number) => {
    if (!selectedAgents.includes(agentId)) {
      setSelectedAgents([...selectedAgents, agentId]);
    }
    setPrimaryAgent(agentId);
  };

  const handleSelectPosition = (positionId: number | null) => {
    if (selectedPosition === positionId) {
      setSelectedPosition(null);
      setFilteredAgents(agentsData);
    } else {
      setSelectedPosition(positionId);
      setFilteredAgents(
        positionId === null
          ? agentsData
          : agentsData.filter((agent) => agent.position_id === positionId)
      );
    }
  };

  const handleConfirmAgents = async () => {
    const agentsDataToSend = selectedAgents.map((agentId) => ({
      id: agentId,
      is_primary: agentId === primaryAgent,
    }));

    try {
      const response: any = await api.post(`/user/agents/update/`, {
        userId,
        mapId,
        agents: agentsDataToSend,
      });

      if (response.data.response) {
        alert(response.data.response.message);
      } else {
        navigate(`/maps/${userId}`);
      }
    } catch (error) {
      console.error('Erro ao atualizar os agentes:', error);
      alert('Você não tem permissão para atualizar o agente deste usuário!');
    }
  };

  return (
    <Main>
      <div
        style={{
          backgroundImage: mapData ? `url(${process.env.PUBLIC_URL}/assets/images/maps/${mapData.path})` : 'none',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundAttachment: 'fixed',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="md" style={{ padding: '20px' }}>
          <Typography variant="h4" align="center" gutterBottom style={{ color: '#fff', marginBottom: '40px' }}>
            {userName}
          </Typography>

          <Box display="flex" justifyContent="center" marginBottom="20px">
            <Box
              onClick={() => handleSelectPosition(null)}
              style={{
                width: '100px',
                height: '100px',
                margin: '0 10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                border: selectedPosition === null ? '3px solid #1976d2' : '1px solid #ccc',
                borderRadius: '2px',
                backgroundColor: '#f5f5f5',
                background: `url(${process.env.PUBLIC_URL}/assets/images/positions/grid.webp)`,
                backgroundSize: '75%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            ></Box>
            {positions.map((position) => (
              <Box
                key={position.id}
                onClick={() => handleSelectPosition(position.id)}
                style={{
                  width: '100px',
                  height: '100px',
                  margin: '0 10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  border: selectedPosition === position.id ? '3px solid #1976d2' : '1px solid #ccc',
                  borderRadius: '2px',
                  background: `url(${process.env.PUBLIC_URL}/assets/images/positions/${position.path})`,
                  backgroundSize: '75%',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              ></Box>
            ))}
          </Box>

          <Grid container spacing={3}>
            {filteredAgents.map((agent) => (
              <Grid item xs={12} sm={6} md={4} key={agent.id}>
                <Card
                  style={{
                    backgroundColor: selectedAgents.includes(agent.id) ? '#1976d2' : '#fff',
                    color: selectedAgents.includes(agent.id) ? '#fff' : '#000',
                    cursor: 'pointer',
                  }}
                >
                  <CardActionArea>
                    <img
                      src={process.env.PUBLIC_URL + '/assets/images/agents/' + agent.path}
                      alt={agent.name}
                      style={{ width: '100%', minHeight: '320px', objectFit: 'cover' }}
                      onClick={() => handleSelectAgent(agent)}
                    />
                    <CardContent>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{agent.name}</Typography>
                        <Radio
                          checked={primaryAgent === agent.id}
                          onChange={() => handleSetPrimaryAgent(agent.id)}
                          value={agent.id}
                          color="primary"
                          name="primary-agent-radio"
                          inputProps={{ 'aria-label': agent.name }}
                        />
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        {positions.find((pos) => pos.id === agent.position_id)?.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmAgents}
            style={{ marginTop: '20px' }}
          >
            Confirmar Agentes
          </Button>
        </Container>
      </div>
    </Main>
  );
};

export default Agents;
