import React, { useState } from 'react';
import { Grid, TextField, Button, Box, Typography, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

const Login: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:899px)'); 
  const [username, setUsername] = useState(''); 
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Hook para navegação

  // Verifica se os campos estão preenchidos
  const isFormFilled = username !== '' && password !== '';

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault(); // Previne o comportamento padrão do formulário

    try {
      const response = await api.post('/login', { username, password });

      if (response.status === 200) {
        // Armazena o token no localStorage
        localStorage.setItem('token', response.data.token);
        
        // Redireciona para a rota /maps
        navigate('/home');
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login failed');
    }
  };

  return (
    <Grid container style={{ height: '100vh' }}>
      <Grid
        item
        xs={12}
        md={4}
        style={{
          backgroundColor: '#f5f5f5',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 2rem',
        }}
      >
        <Box>
          <Box mb={4} display="flex" justifyContent="center">
            <img src={`${process.env.PUBLIC_URL}/assets/images/logo/imortal.png`} alt="Riot Games" style={{ width: '100px' }} />
          </Box>

          <Box component="form" onSubmit={handleLogin}>
            <TextField
              label="Username"
              variant="filled"
              fullWidth
              margin="normal"
              InputProps={{ disableUnderline: true }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="filled"
              fullWidth
              margin="normal"
              InputProps={{ disableUnderline: true }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isFormFilled} // Botão desabilitado se o formulário não estiver preenchido
                style={{
                  backgroundColor: isFormFilled ? '#FF0000' : '#E0E0E0', // Cor do botão: vermelho se preenchido, gelo se não
                  color: isFormFilled ? '#FFFFFF' : '#9E9E9E', // Cor do ícone: branco se preenchido, cinza se não
                  width: '60px',
                  height: '56px',
                  borderRadius: '16px',
                  minWidth: 'unset',
                  padding: 0,
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '24px' }} />
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>

      {!isMobile && (
        <Grid
          item
          xs={12}
          md={8}
          style={{
            backgroundColor: '#000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <video
            autoPlay
            loop
            muted
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          >
            <source src={`${process.env.PUBLIC_URL}/assets/video/login.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Grid>
      )}
    </Grid>
  );
};

export default Login;