import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client'; 
import 'react-toastify/dist/ReactToastify.css';


const container:any = document.getElementById('root');

const root = createRoot(container); 

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
