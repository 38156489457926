import React from 'react';
import { Box } from '@mui/material';
import Navbar from '../Navbar';

interface MainProps {
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Navbar />
      <Box sx={{ flexGrow: 1, paddingTop: '54px' }}>
        {children}
      </Box>
    </Box>
  );
};

export default Main;
